import React from "react";
import { graphql, navigate } from "gatsby";

import { portrait, stylizeIntro, heading, subtitle, buttonStylize } from "./stylize.module.scss";
import StylizePortrait from "../../assets/images/svg/portrait-stylize.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyList } from "../../redux/survey/survey.actions";
import { selectIsSurveyListEmpty } from "../../redux/survey/survey.selectors";
import { processRouteString } from "../../apps/utility/routes.utility";

import MainLayout from "../../layouts/main-layout";
import Section from "../molecules/section";
import Title from "../atoms/title";
import Button from "../atoms/button";

const Stylize = () => {
    const isSurveyListEmpty = useSelector(selectIsSurveyListEmpty);
    const dispatch = useDispatch();
    const onClick = () => {
        if (isSurveyListEmpty) {
            dispatch(getSurveyList());
        }
        navigate(processRouteString("SURVEY"));
    };

    return (
        <MainLayout>
            <Section column={"narrow"} columnTablet={"regular"} className={portrait}>
                <StylizePortrait />
            </Section>

            <Section column={"full"} columnTablet={"full"} className={stylizeIntro}>
                <Title size={"large"} align={"center"} className={heading}>
                    <span>Wyczarujmy Twój styl!</span>
                </Title>
                <div className={subtitle}>
                    <p>
                        Teraz chcemy poznać Cię bliżej. Jaka jesteś, co lubisz, jaki masz styl. Te
                        informacje umożliwią nam stworzenie stylizacji dedykowanej właśnie Tobie!
                    </p>
                </div>
                <Section column={"narrow"} className={buttonStylize}>
                    <Button color="primary" type="filled" onClick={onClick}>
                        Rozpocznij ankietę
                    </Button>
                </Section>
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Stylize;
